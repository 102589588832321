export function defaultColor() {

  let defaultColor = localStorage.getItem('defaultColor');
  let bodyStyles = document.body.style;

  switch (defaultColor) {
    case 'default':
      bodyStyles.setProperty('--primaryLight', '#718F9C')
      bodyStyles.setProperty('--primary', '#556D77')
      bodyStyles.setProperty('--primaryDark', '#314148')
      break;
    case 'grey_l':
      bodyStyles.setProperty('--primaryLight', '#BDCACF')
      bodyStyles.setProperty('--primary', '#89A1AC')
      bodyStyles.setProperty('--primaryDark', '#65818C')
      break;
    case 'black':
      bodyStyles.setProperty('--primaryLight', '#363636')
      bodyStyles.setProperty('--primary', '#1B1B1B')
      bodyStyles.setProperty('--primaryDark', '#000000')
      break;
    case 'brown_l':
      bodyStyles.setProperty('--primaryLight', '#B29A92')
      bodyStyles.setProperty('--primary', '#A08277')
      bodyStyles.setProperty('--primaryDark', '#8E695B')
      break;
    case 'brown_d':
      bodyStyles.setProperty('--primaryLight', '#8E695B')
      bodyStyles.setProperty('--primary', '#725247')
      bodyStyles.setProperty('--primaryDark', '#51352F')
      break;
    case 'orange':
      bodyStyles.setProperty('--primaryLight', '#FF8617')
      bodyStyles.setProperty('--primary', '#F16910')
      bodyStyles.setProperty('--primaryDark', '#AD4500')
      break;
    case 'yellow_l':
      bodyStyles.setProperty('--primaryLight', '#FFEE58')
      bodyStyles.setProperty('--primary', '#FFED2E')
      bodyStyles.setProperty('--primaryDark', '#E0B415')
      break;
    case 'yellow_d':
      bodyStyles.setProperty('--primaryLight', '#FFCC28')
      bodyStyles.setProperty('--primary', '#FFB120')
      bodyStyles.setProperty('--primaryDark', '#FFA21D')
      break;
    case 'gold':
      bodyStyles.setProperty('--primaryLight', '#BF9A07')
      bodyStyles.setProperty('--primary', '#927502')
      bodyStyles.setProperty('--primaryDark', '#715D10')
      break;
    case 'green_l':
      bodyStyles.setProperty('--primaryLight', '#68E035')
      bodyStyles.setProperty('--primary', '#389F0B')
      bodyStyles.setProperty('--primaryDark', '#287308')
      break;
    case 'green_d':
      bodyStyles.setProperty('--primaryLight', '#017D28')
      bodyStyles.setProperty('--primary', '#05561F')
      bodyStyles.setProperty('--primaryDark', '#033814')
      break;
    case 'blue_l':
      bodyStyles.setProperty('--primaryLight', '#30DBF1')
      bodyStyles.setProperty('--primary', '#00C3DB')
      bodyStyles.setProperty('--primaryDark', '#088495')
      break;
    case 'blue_d':
      bodyStyles.setProperty('--primaryLight', '#0022CC')
      bodyStyles.setProperty('--primary', '#00157D')
      bodyStyles.setProperty('--primaryDark', '#000D50')
      break;
    case 'lilac':
      bodyStyles.setProperty('--primaryLight', '#9433D0')
      bodyStyles.setProperty('--primary', '#740DB4')
      bodyStyles.setProperty('--primaryDark', '#540785')
      break;
    case 'purple':
      bodyStyles.setProperty('--primaryLight', '#6428D4')
      bodyStyles.setProperty('--primary', '#4A0CBD')
      bodyStyles.setProperty('--primaryDark', '#30067D')
      break;
    case 'pink_l':
      bodyStyles.setProperty('--primaryLight', '#FBB0C8')
      bodyStyles.setProperty('--primary', '#FF97B8')
      bodyStyles.setProperty('--primaryDark', '#F97BA2')
      break;
    case 'pink_d':
      bodyStyles.setProperty('--primaryLight', '#F76794')
      bodyStyles.setProperty('--primary', '#F43B75')
      bodyStyles.setProperty('--primaryDark', '#951644')
      break;
    case 'red_l':
      bodyStyles.setProperty('--primaryLight', '#EF8A86')
      bodyStyles.setProperty('--primary', '#F86C63')
      bodyStyles.setProperty('--primaryDark', '#FD5D47')
      break;
    case 'red_d':
      bodyStyles.setProperty('--primaryLight', '#F36659')
      bodyStyles.setProperty('--primary', '#C93226')
      bodyStyles.setProperty('--primaryDark', '#6D1212')
      break;
    default:
      break;
  }

}
